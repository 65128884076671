$primary: #005BEA;
$blue-1: #0182F1;
$blue-2: #0190D8;
$yellow-1: #F7BD47;
$yellow-2: #E7E500;
$light-blue: #E3ECF3;
$dark-gray-1: #505050;
$dark-blue-1: #1D689E;
$gray-blue-1: var(--h2-color);
$gray-1: #E8E8E8;
$gray-2: #848484;
$nav-active: #0063CD;
$form-input-color: $blue-2;

$nav-logo-height: 70px;
$navbar-padding: 2rem;
$navbar-height: calc($nav-logo-height + $navbar-padding * 2);

$grid-breakpoints: (
  xs: 0,
  iphoneMax-P: 430px,
  sm: 576px,
  md: 768px,
  iphoneMax-L: 932px,
  lg: 992px,
  ipad: 1024px,
  xl: 1200px,
  xxl: 1400px
);
