@use 'styles/variables' as var;
@use 'styles/mixin';

.container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  &.open::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}

.navbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: var.$navbar-padding;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  ul {
    padding-left: 1rem;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 2 900px;
  }
  li {
    white-space: nowrap;
  }
  a {
    position: relative;
    display: inline-block;
  }
  .logo {
    min-width: 0;
    padding-right: 1rem;
    margin-right: auto;
    font-size: 0;
    flex: 0 1 auto;
    svg {
      max-width: 100%;
      height: var.$nav-logo-height;
      pointer-events: none;
    }
  }
  @include mixin.MQ(xxl, up) {
    max-width: 70%;
  }
  @include mixin.MQ(md, up) {
    ul {
      font-size: 1.3rem;
    }
    li:not(:last-child) {
      padding-right: 1rem;
    }
    a:global(.active) {
      &::after {
        content: '';
        position: absolute;
        left: -5px;
        right: -5px;
        bottom: -0.6rem;
        border-bottom: 5px solid var.$nav-active;
      }
    }
    a:global(.active.disabled) {
      pointer-events: none;
    }
    .menuButton {
      display: none;
    }
  }
  @include mixin.MQ(md, down) {
    padding: 1rem;
    ul {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      padding: calc(1rem + var.$nav-logo-height) 1rem 1rem;
      flex-direction: column;
      background-color: #D5ECFF;
      visibility: hidden;
      box-shadow: 0 4px 4px rgba(#000, 25%);
    }
    li {
      width: 100%;
      padding: 1.5rem 0;
      color: #0170C1;
      text-align: center;
      &:not(:last-child) {
        border-bottom: 1px solid #fff;
      }
    }
    a:global(.active) {
      color: #EF8E3F;
    }
    .logo {
      z-index: 1;
    }
    .menuButton  {
      line-height: 0;
      border: none;
      background-color: transparent;
    }
    .open & {
      ul{
        visibility: visible;
      }
      .menuButton {
        z-index: 0;
      }
    }
  }
  @include mixin.MQ(iphoneMax-P, down) {
    .logo svg {
      height: 50px;
    }
  }
}

.mobileSocial {
  margin: 0 0.5rem;
  white-space: nowrap;
  z-index: -1;
  > * {
    width: 30px;
    margin: 0 0.5rem;
  }
  img {
    width: 100%;
  }
  @include mixin.MQ(md, up) {
    display: none;
  }
}

.goTop {
  position: fixed;
  right: 1rem;
  bottom: 4rem;
  width: 70px;
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.2s;
  img { width: 100%; }
  &.show {
    opacity: 1;
    pointer-events: auto;
  }
}

.footer {
  text-align: center;
  background-color: var.$light-blue;
  a {
    display: inline-block;
  }
  .logo {
    width: 199px;
    margin: 1rem;
  }
  @include mixin.MQ(md, down) {
    padding: 1rem;
  }
}

.social {
  padding: 1rem 2rem;
  > a {
    margin: 0 0.8rem;
    font-size: 0;
  }
  svg {
    height: 27px;
    &:hover {
      color: #01AFF8;
    }
  }
}
