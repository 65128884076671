@use 'variables' as var;
@use 'sass:math';

%linear-bg-dot {
  position: absolute;
  top: 100%;
  width: 22px;
  height: 22px;
  background-image: linear-gradient(to right, #547AFE, #40CDE6);
  border-radius: 50%;
  transform: translateY(-50%);
}

@mixin response-padding($padding-y: 1.5rem) {
  padding: $padding-y var.$default-gap-x;
}

@function check-breakpoint ($breakpoint) {
  $width: map-get(var.$grid-breakpoints, $breakpoint);
  @return if($width != null, $width, if(unitless($breakpoint), $breakpoint * 1px, $breakpoint));
}

@mixin MQ ($breakpoint, $scope: '') {
  @if (type-of($breakpoint) == list) {
    $list:
      check-breakpoint(nth($breakpoint, 1)),
      check-breakpoint(nth($breakpoint, 2));
    $min: min($list...);
    $max: max($list...);
    @media (min-width: $min) and (max-width: $max) { @content; }
  }
  @else {
    $width: check-breakpoint($breakpoint);
    @if ($scope == 'up') {
      @media (min-width: ($width + 1)) { @content; }
    }
    @else if ($scope == 'down') {
      @media (max-width: ($width + 0.02)) { @content; }
    }
    @else {
      @error 'Property $scope should be `up` or `down`';
    }
  }
}

@mixin flex-limit($column-amount) {
  flex-basis: math.div(100%, $column-amount);
  max-width: math.div(100%, $column-amount);
}
