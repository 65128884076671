:root {
  --section-padding: 2rem;
  --h2-color: #4C89D3;
}

html {
  height: 100%;
  font-family: 'Helvetica', 'Arial', sans-serif;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

ul {
  list-style-type: none;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  cursor: pointer;
  font-size: 1rem;
}

picture  {
  display: block;
  line-height: 0;
}

section, footer {
  position: relative;
  padding: var(--section-padding);
  z-index: 0;
}

.h2-size {
  font-size: 2.7rem;
  color: var(--h2-color);
}

@media (max-width: 768.02px) {
  .h2-size {
    font-size: 2rem;
  }
}
@media (max-width: 430.02px) {
  :root {
    --section-padding: 1rem;
  }
  .h2-size {
    font-size: 1.3rem;
  }
}
